/* Section Parcours */
.parcours-section {
    padding: 50px;
    background-color: #f5f5f5;  /* Couleur de fond pour faire ressortir les logos */
    text-align: center;
  }
  
  .parcours-title {
    font-size: 45px;
    color: #379683;
    margin-bottom: 40px;
  }
  
  /* Conteneur des logos des écoles */
  .school-logos {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  /* Conteneur pour chaque école */
  .school-container {
    margin: 20px;
    text-align: center;
    cursor: pointer;
    position: relative;
  }
  
  /* Style des logos des écoles */
  .school-logo {
    height: 150px;  /* Hauteur fixe des logos */
    width: 250px;   /* Largeur fixe des logos */
    object-fit: contain;  /* Permet de garder les proportions des logos */
    transition: transform 0.3s ease;
  }
  
  
  .school-logo:hover {
    transform: scale(1.1);  /* Zoom léger au survol */
  }
  
  /* Détails de la formation */
  .school-details {
    display: none;
    margin-top: 20px;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    color: #0C0C0C;
    font-size: 16px;
    transition: all 0.5s ease;
    opacity: 0;
    transform: scale(0.9);  /* Effet initial */
  }
  
  /* Activer les détails de la formation avec l'animation */
  .school-details.active {
    display: block;
    opacity: 1;
    transform: scale(1);  /* Grossissement à 100% */
  }
  